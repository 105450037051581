import style from './heading-button.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { Film as FilmData } from '@lib/types';

type Props = {
    heading?: string;
    films: FilmData[];
};

const HeadingButton = ({ heading, films }: Props) => {
    const navigate = useNavigate();

    const handleShowFilmset = (): void => {
        navigate(`/filmset/${heading}`, {
            state: { films, title: heading },
        });
    };

    return (
        <div className={style.heading}>
            {heading && <h2 className={style.h2}>{heading}</h2>}
            <button className={style.filmsetButton} onClick={handleShowFilmset}>
                Показать подборку
            </button>
        </div>
    );
};

export default HeadingButton;
