import { createSlice } from '@reduxjs/toolkit';
import { HomepageFilms } from '@lib/types';
import { fetchHomepageFilms } from '@redux/thunks/fetch-films-TMDB-thunk';

const initialState: HomepageFilms = {
    filmDetails: null,
    recommended: [],
    trending: [],
    newReleases: [],
    loading: false,
    error: null,
};

const homepageFilmsSlice = createSlice({
    name: 'films',
    initialState,
    reducers: {
        clearFilms: (state) => {
            state.recommended = [];
            state.trending = [];
            state.newReleases = [];
            state.filmDetails = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomepageFilms.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchHomepageFilms.fulfilled, (state, action) => {
                state.loading = false;
                state.recommended = action.payload.recommended;
                state.trending = action.payload.trending;
                state.newReleases = action.payload.newReleases;
            })
            .addCase(fetchHomepageFilms.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to load films';
            });
    },
});

export const { clearFilms } = homepageFilmsSlice.actions;
export default homepageFilmsSlice.reducer;
