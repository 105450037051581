import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '@redux/slices/user-slice';

const useAuthFromStorage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const username = localStorage.getItem('authUsername');
        const email = localStorage.getItem('authEmail');
        const userId = localStorage.getItem('authUserId');

        if (token && username && email && userId) {
            dispatch(login({ token, username, email, userId }));
        } else {
            localStorage.clear();
        }
    }, [dispatch]);
};

export default useAuthFromStorage;
