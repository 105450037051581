import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFilmsByIds } from '@redux/thunks/fetch-films-TMDB-thunk';
import type { Film } from '@lib/types';

interface FilmsState {
    films: Film[];
    loading: boolean;
    error: string | null;
}

const initialState: FilmsState = {
    films: [],
    loading: false,
    error: null,
};

const filmsByIdsSlice = createSlice({
    name: 'films',
    initialState,
    reducers: {
        clearFilmById: (state, action: PayloadAction<number>) => {
            state.films = state.films.filter(
                (film) => film.id !== action.payload
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilmsByIds.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                fetchFilmsByIds.fulfilled,
                (state, action: PayloadAction<Film[]>) => {
                    state.loading = false;
                    state.films = action.payload;
                }
            )
            .addCase(fetchFilmsByIds.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message || 'Failed to fetch films by IDs';
            });
    },
});

export const { clearFilmById } = filmsByIdsSlice.actions;
export default filmsByIdsSlice.reducer;
