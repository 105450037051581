import style from './film-page-stars.module.scss';
import { IoStar } from 'react-icons/io5';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import type { Rating } from '@lib/types';
import { db } from '@firebaselocal';
import { get, ref, set } from 'firebase/database';
import { filmDataFBSelector } from '@redux/selectors/selectors';
import { useSelector } from 'react-redux';

type Props = {
    filmId: number;
    userData: any;
};

const FilmPageStars = ({ filmId, userData }: Props) => {
    const filmDataFB = useSelector(filmDataFBSelector);
    const navigate = useNavigate();
    const isUserAuth = !!userData;
    const initialRating = filmDataFB?.ratings?.[userData?.uid ?? ''] || null;
    const [rating, setRating] = useState<number | null>(initialRating ?? 0);
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);
    const userId = userData?.uid;

    const handleMouseEnter = (index: number) => {
        setHoveredRating(index + 1);
    };

    const handleMouseLeave = () => {
        setHoveredRating(null);
    };

    // TODO: сортировать пользовательский рейтинг по дате
    const addToAverageRating = async (data: Rating): Promise<void> => {
        const ratingsRef = ref(db, `films/${data.filmId}/ratings`);
        const ratingsSnapshot = await get(ratingsRef);
        const ratings = ratingsSnapshot.val();

        if (!ratings) {
            return;
        }

        const totalRatings = Object.values(ratings);
        const sum = totalRatings.reduce(
            (acc: number, rating) => acc + (Number(rating) || 0),
            0
        );

        const averageUsersRating = (sum / totalRatings.length).toFixed(2);

        const averageUsersRatingRef = ref(
            db,
            `films/${data.filmId}/average_rating`
        );
        await set(averageUsersRatingRef, Number(averageUsersRating));
    };

    const sendData = async (data: Rating): Promise<void> => {
        const dataScore = data.score[0];

        try {
            const ratingRef = ref(
                db,
                `films/${data.filmId}/ratings/${dataScore.userId}`
            );
            await set(ratingRef, dataScore.rating);

            const ratingForUserRef = ref(
                db,
                `users/${userId}/ratings/${filmId}`
            );

            await set(ratingForUserRef, dataScore.rating);

            toast.success('Ваша оценка сохранена!');
        } catch (error) {
            toast.error(`Ошибка отправки данных:${error}`);
        }
    };

    const setUserRatingToFilm = (rating: number) => {
        if (isUserAuth) {
            setRating(rating);
            const ratingData: Rating = {
                filmId,
                score: [
                    {
                        userId: userData.uid,
                        rating,
                    },
                ],
            };
            sendData(ratingData);
            addToAverageRating(ratingData);
        } else {
            toast.error('Чтобы оценить фильм, необходимо авторизоваться');
        }
    };

    return (
        <div className={style.container}>
            <div className={style.starsAndRate}>
                <div className={style.starsContainer}>
                    {Array.from({ length: 10 }).map((_, index) => (
                        <button
                            className={style.stars}
                            key={index}
                            onClick={() => setUserRatingToFilm(index + 1)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            data-highlighted={
                                index < (hoveredRating ?? rating ?? 0)
                            }
                        >
                            <IoStar className={style.star} />
                        </button>
                    ))}
                </div>
                <div className={style.rating}>
                    {(hoveredRating || (rating ?? 0) > 0) && (
                        <p>{hoveredRating ?? rating ?? 0} / 10</p>
                    )}
                </div>
            </div>

            {!isUserAuth && (
                <div className={style.warning}>
                    <button
                        onClick={() => navigate('/auth/login')}
                        className={style.authButton}
                    >
                        Войти
                    </button>
                </div>
            )}
        </div>
    );
};

export default FilmPageStars;
