import style from './loader.module.scss';
import React from 'react';

type Props = {
    radius?: number;
};
const Loader = ({ radius }: Props) => {
    return (
        <>
            {radius && (
                <div className={style.container}>
                    <div
                        className={style.spinner}
                        style={{
                            width: `${radius}px `,
                            height: `${radius}px `,
                        }}
                    />
                </div>
            )}
            {!radius && (
                <div className={style.containerLarge}>
                    <div className={style.spinner} />
                </div>
            )}
        </>
    );
};

export default Loader;
