import style from './button-add-to-favorite.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '@redux/selectors/selectors';
import { db } from '@firebaselocal';
import { FcLike } from 'react-icons/fc';

import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ref, push, get, remove } from 'firebase/database';

type Props = {
    filmId: number;
};
import { setUserLists } from '@redux/slices/user-slice';

const ButtonAddToFavorite = ({ filmId }: Props) => {
    const userData = useSelector(userDataSelector);
    const [isFilmLiked, setIsFilmLiked] = useState(false);
    const userId = userData.userId;
    const dispatch = useDispatch();

    useEffect(() => {
        const checkIsLiked = async () => {
            if (userId) {
                const likedFilmRef = ref(db, `users/${userId}/lists/Избранное`);
                const snapshot = await get(likedFilmRef);
                const favorites = snapshot.val();

                setIsFilmLiked(
                    favorites && Object.values(favorites).includes(filmId)
                );
                dispatch(setUserLists(snapshot.val()));
            }
        };

        checkIsLiked();
    }, [userId, filmId, dispatch]);

    const toggleFavorite = async () => {
        if (!userId) {
            toast.error('Чтобы сохранить фильм необходимо авторизоваться');
            return;
        }

        try {
            const likedFilmRef = ref(db, `users/${userId}/lists/Избранное`);

            if (isFilmLiked) {
                const snapshot = await get(likedFilmRef);
                const favorites = snapshot.val();
                const filmKey = Object.keys(favorites).find(
                    (key) => favorites[key] === filmId
                );

                if (filmKey) {
                    await remove(
                        ref(db, `users/${userId}/lists/Избранное/${filmKey}`)
                    );
                    toast('Фильм удален из избранного');
                    setIsFilmLiked(false);
                }
            } else {
                await push(likedFilmRef, filmId);
                toast.success('Фильм добавлен в избранное');
                setIsFilmLiked(true);
            }
        } catch (error) {
            toast.error(`Ошибка ${error}`);
        }
    };

    return (
        <button
            onClick={toggleFavorite}
            className={style.container}
            data-is-liked={isFilmLiked}
        >
            <FcLike className={style.icon} />
        </button>
    );
};

export default ButtonAddToFavorite;
