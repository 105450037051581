import style from './bookmarks-page.module.scss';
import useAuthStatus from '@lib/hooks/use-auth-status';
import Loader from '@components/loader/loader';
import { userListsFromFBSelector } from '@redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDataFromFB } from '@redux/thunks/fetch-user-data-FB-thunk';
import { AppDispatch } from '@redux/store/store';
import { useEffect } from 'react';
import { userDataSelector } from '@redux/selectors/selectors';
import { fetchListFilmsByIds } from '@redux/thunks/fetch-films-TMDB-thunk';
import ListOfFilms from '@components/homepage-films/list-of-films';
import {
    loadingListsOfFilmsByIdsSelector,
    dataListsOfFilmsByIdsSelector,
} from '@redux/selectors/selectors';

const BookmarksPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { userData, loadingUserData } = useAuthStatus();

    const authDataFB = useSelector(userDataSelector);
    const userDataFBLoading = authDataFB.loading;
    const userListsFB = useSelector(userListsFromFBSelector);

    const filmsDataTMDB = useSelector(dataListsOfFilmsByIdsSelector);
    const filmsLoadingTMDB = useSelector(loadingListsOfFilmsByIdsSelector);

    const filmIds = Object.entries(userListsFB || {}).map(
        ([listName, films]) => ({
            listName,
            filmIds: Object.values(films),
        })
    );

    useEffect(() => {
        if (userData) {
            dispatch(fetchUserDataFromFB(userData?.uid));
        }
    }, [dispatch, userData]);

    useEffect(() => {
        if (!userDataFBLoading) {
            filmIds.forEach(({ listName, filmIds }) => {
                dispatch(fetchListFilmsByIds({ listName, filmsIds: filmIds }));
            });
        }
    }, [dispatch, userDataFBLoading]);

    const isLoading = loadingUserData || userDataFBLoading || filmsLoadingTMDB;

    if (isLoading) {
        return <Loader />;
    }

    if (filmIds.length === 0) {
        return (
            <div className={style.nothing}>
                <p>Пока что нет сохраненных фильмов</p>
            </div>
        );
    }

    // TODO: обработать ошибки

    if (userData) {
        return (
            <div className={style.container}>
                <section className={style.userFilmSets}>
                    {filmIds.map(({ listName, filmIds }) => (
                        <div className={style.films} key={listName}>
                            {filmsDataTMDB && filmsDataTMDB[listName] && (
                                <ListOfFilms
                                    heading={listName}
                                    films={filmsDataTMDB[listName]
                                        .filter((film) =>
                                            filmIds.includes(film.id)
                                        )
                                        .reverse()}
                                />
                            )}
                        </div>
                    ))}
                </section>
                {/* TODO: доделать возможность создания подборки */}
            </div>
        );
    }
};

export default BookmarksPage;
