import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import UserPage from '@pages/user-page/user-page';
import NotFoundPage from '@pages/not-found-page/not-found-page';
import LoginPage from '@pages/auth/login-page/login-page';
import RegistrationPage from '@pages/auth/registration-page/registration-page';
import HomePage from '@pages/home-page/home-page';
import FilmPage from '@pages/film-page/film-page';
import FilmsetPage from '@pages/filmset-page/filmset-page';
import BookmarksPage from '@pages/bookmarks-page/bookmarks-page';
import useAuthStatus from '@lib/hooks/use-auth-status';
import Loader from '@components/loader/loader';
import { useEffect } from 'react';

const AppRoutes = () => {
    const location = useLocation();

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof ym === 'function') {
            ym(98983881, 'hit', { pathname: location.pathname });
        }
    }, [location]);

    const { userData, loadingUserData } = useAuthStatus();
    const isUserAuthed = !!userData;
    const goToHomePage = <Navigate to="/" />;

    if (loadingUserData) {
        return <Loader />;
    }

    if (!loadingUserData) {
        return (
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/film/:id" element={<FilmPage />} />
                <Route path="/filmset/:id" element={<FilmsetPage />} />
                <Route path="/*" element={<NotFoundPage />} />

                <Route
                    path="/bookmarks"
                    element={isUserAuthed ? <BookmarksPage /> : goToHomePage}
                />

                <Route
                    path="/userpage"
                    element={isUserAuthed ? <UserPage /> : goToHomePage}
                />

                <Route
                    path="/auth/login"
                    element={!isUserAuthed ? <LoginPage /> : goToHomePage}
                />

                <Route
                    path="/auth/register"
                    element={
                        !isUserAuthed ? <RegistrationPage /> : goToHomePage
                    }
                />
            </Routes>
        );
    }
};

export default AppRoutes;
