import { useState, useEffect } from 'react';
import {
    getAuth,
    onAuthStateChanged,
    User as FirebaseUser,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { setUsername, setUserId } from '@redux/slices/user-slice';

const useAuthStatus = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState<FirebaseUser | null>(null);
    const [loadingUserData, setLoadingUserData] = useState<boolean>(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUserData(user);
            if (userData?.uid && userData.displayName) {
                dispatch(setUsername(userData.displayName));
                dispatch(setUserId(userData.uid));
            }
            setLoadingUserData(false);
        });
        return () => unsubscribe();
    }, [dispatch]);

    return { userData, loadingUserData };
};

export default useAuthStatus;
