import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@redux/store/store';
import { fetchFilmById } from '@redux/thunks/fetch-films-TMDB-thunk';
import { fetchFilmDataFromFB } from '@redux/thunks/fetch-films-FB-thunk';
import style from './film-page.module.scss';
import Loader from '@components/loader/loader';
import Player from '@components/player/player';
import {
    errorFilmByIdSelector,
    FilmByIdDetailsSelector,
    loadingFilmByIdSelector,
} from '@redux/selectors/selectors';
import { format } from 'date-fns';
import FilmPageTopSection from '@components/film-page-sections/film-page-top-section/film-page-top-section';
import Stars from '@components/film-page-sections/film-page-stars/film-page-stars';
import useAuthStatus from '@lib/hooks/use-auth-status';
import { loadingFilmDataFBSelector } from '@redux/selectors/selectors';
import {
    clearFilmDataFromFB,
    setCurrentFilmId,
} from '@redux/slices/film-from-FB-slice';
import Comments from '@components/film-page-sections/film-page-bottom-section/comments/comments';

const FilmPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const filmId = Number(id);
    const filmDetails = useSelector(FilmByIdDetailsSelector);
    const errorTMDB = useSelector(errorFilmByIdSelector);
    const loadingTMDB = useSelector(loadingFilmByIdSelector);
    const loadingFB = useSelector(loadingFilmDataFBSelector);

    const { loadingUserData, userData } = useAuthStatus();

    // TODO: возможно стоит перенести в film-page-top-section
    const releaseDate = filmDetails?.release_date
        ? format(new Date(filmDetails.release_date), 'dd.MM.yyyy')
        : 'Нет данных';
    const genres = filmDetails?.genres
        ? filmDetails.genres.map((genre) => genre.name).join(', ')
        : 'Нет данных';

    useEffect(() => {
        dispatch(clearFilmDataFromFB());
        if (filmId) {
            dispatch(fetchFilmById(filmId));
            dispatch(fetchFilmDataFromFB(filmId));
            dispatch(setCurrentFilmId(filmId));
        }
    }, [dispatch, filmId]);

    if (loadingTMDB || loadingUserData || loadingFB) {
        return <Loader />;
    }

    if (errorTMDB) {
        if (
            errorTMDB ===
                'Ошибка загрузки фильма: Request failed with status code 404' ||
            errorTMDB ===
                'Ошибка загрузки фильма по ID Request failed with status code 404'
        ) {
            navigate('/not-found-page');
        }
        return <div className={style.error}>{errorTMDB}</div>;
    }

    if (!filmDetails || !filmDetails.id) {
        return <div className={style.notFound}>Фильм не найден</div>;
    }

    return (
        <div className={style.container}>
            <FilmPageTopSection
                filmDetails={filmDetails}
                genres={genres}
                releaseDate={releaseDate}
            />

            <section className={style.player}>
                <Player TMDB_ID={filmDetails.id} />
                <Stars filmId={filmId} userData={userData} />
            </section>

            <div className={style.commentsContainer}>
                <Comments />
            </div>
        </div>
    );
};

export default FilmPage;
