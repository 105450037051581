import { createSlice } from '@reduxjs/toolkit';
import { Film } from '@lib/types';
import { fetchListFilmsByIds } from '@redux/thunks/fetch-films-TMDB-thunk';

type SliceState = {
    films: { [key: string]: Film[] };
    loading: boolean;
    error: string | null;
};
const initialState: SliceState = {
    films: {},
    loading: false,
    error: null,
};

const bookmarksFilmsSlice = createSlice({
    name: 'films',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchListFilmsByIds.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListFilmsByIds.fulfilled, (state, action) => {
                const { listName, films } = action.payload;
                state.loading = false;
                state.films[listName] = films;
            })
            .addCase(fetchListFilmsByIds.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to load films';
            });
    },
});

export default bookmarksFilmsSlice.reducer;
