import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { getApiConfig, API_BASE_URL } from '@lib/api';
import axios from 'axios';
import { Film } from '@lib/types';
import { ref, get } from 'firebase/database';
import { db } from '../../firebase';

const config = getApiConfig();

type Props = string | number;

const extractErrorMessage = (error: unknown): string => {
    if (error && typeof error === 'object' && 'message' in error) {
        return (error as { message: string }).message;
    } else if (typeof error === 'string') {
        return error;
    }
    return 'Неизвестная ошибка';
};

const createFilmThunk = (
    set: Props,
    name: Props
): AsyncThunk<Film[], void, {}> => {
    return createAsyncThunk(`films/${set}`, async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}/movie/${name}`,
                config
            );
            return response.data.results;
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            console.error(
                `Ошибка загрузки фильмов TMDB: ${set} - ${name}: ${errorMessage}`
            );
            throw new Error(`Ошибка загрузки фильмов: ${errorMessage}`);
        }
    });
};

export const fetchNewReleasesFilms = createFilmThunk(
    'fetchNewReleasesFilms',
    'now_playing'
);

export const fetchTrendingFilms = createFilmThunk(
    'fetchTrendingFilms',
    'top_rated'
);

export const fetchRecomendedFilms = createFilmThunk(
    'fetchRecomendedFilms',
    'upcoming'
);

export const fetchFilmById = createAsyncThunk(
    'films/fetchFilmById',
    async (filmId: number) => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}/movie/${filmId}`,
                config
            );
            return response.data;
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            console.error(
                `Ошибка загрузки фильма по ID ${filmId}: ${errorMessage}`
            );
            throw new Error(`Ошибка загрузки фильма по ID ${errorMessage}`);
        }
    }
);

export const fetchFilmByTitle = createAsyncThunk(
    'films/fetchFilmByTitle',
    async (filmTitle: string | number) => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}/search/movie?query=${filmTitle}`,
                config
            );
            return response.data.results;
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            console.error(
                `Ошибка при поиске фильма"${filmTitle}": ${errorMessage}`
            );
            throw new Error(`Ошибка при поиске фильма ${errorMessage}`);
        }
    }
);

export const fetchAllRatingsFromFB = async (): Promise<{
    [filmId: number]: number;
}> => {
    try {
        const ratingsRef = ref(db, 'films');

        const snapshot = await get(ratingsRef);

        if (!snapshot.exists()) {
            return {};
        }

        const data = snapshot.val();
        const ratings: { [filmId: number]: number } = {};

        for (const filmId in data) {
            if (data[filmId].average_rating !== undefined) {
                ratings[Number(filmId)] = data[filmId].average_rating;
            }
        }
        return ratings;
    } catch (error) {
        const errorMessage = extractErrorMessage(error);
        console.error(`Ошибка загрузки рейтингов из Firebase: ${errorMessage}`);
        throw new Error(
            `Ошибка загрузки рейтингов из Firebase: ${errorMessage}`
        );
    }
};

export const fetchHomepageFilms = createAsyncThunk(
    'films/fetchHomepageFilms',
    async (_, { dispatch }) => {
        try {
            let recommended = await dispatch(fetchRecomendedFilms()).unwrap();
            const trending = await dispatch(fetchTrendingFilms()).unwrap();
            const newReleases = await dispatch(
                fetchNewReleasesFilms()
            ).unwrap();

            recommended = recommended.reverse();

            const ratings = await fetchAllRatingsFromFB();

            const mergeRatings = (films: Film[]) =>
                films.map((film) => ({
                    ...film,
                    average_rating: ratings[film.id] || null,
                }));

            return {
                recommended: mergeRatings(recommended),
                trending: mergeRatings(trending),
                newReleases: mergeRatings(newReleases),
            };
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            console.error(errorMessage);
            throw new Error(errorMessage);
        }
    }
);

export const fetchListFilmsByIds = createAsyncThunk(
    'films/fetchListFilmsByIds',
    async (
        { listName, filmsIds }: { listName: string; filmsIds: number[] },
        { dispatch }
    ) => {
        try {
            const ratings = await fetchAllRatingsFromFB();

            const filmsPromises = filmsIds.map((id) =>
                dispatch(fetchFilmById(id))
            );

            const films = await Promise.all(filmsPromises);

            return {
                listName,
                films: films.map((action) => ({
                    ...action.payload,
                    average_rating: ratings[action.payload.id] || null,
                })),
            };
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            console.error(
                `Ошибка загрузки списка фильмов пользователя: ${errorMessage}`
            );
            throw new Error(
                `Ошибка загрузки списка фильмов пользователя: ${errorMessage}`
            );
        }
    }
);

type FetchFilmsByIdsProps = {
    filmsIds: number[];
};

export const fetchFilmsByIds = createAsyncThunk(
    'films/fetchFilmsByIds',
    async ({ filmsIds }: FetchFilmsByIdsProps, { dispatch }) => {
        try {
            const filmsPromises = filmsIds.map((id) =>
                dispatch(fetchFilmById(id)).unwrap()
            );

            const films = await Promise.all(filmsPromises);

            return films;
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            console.error(`Ошибка загрузки фильмов по ID: ${errorMessage}`);
            throw new Error(`Ошибка загрузки фильмов ${errorMessage}`);
        }
    }
);
