import React, { useState, useEffect, useRef } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoEnter } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { logout } from '@redux/slices/user-slice';
import LinkButton from '@components/link-button/link-button';
import { IoBookmarks } from 'react-icons/io5';
import { FaUser } from 'react-icons/fa6';
import { getAuth, signOut } from 'firebase/auth';
import style from './hamburger-menu.module.scss';
import { clearSearchResults } from '@redux/slices/search-films-slice';
import { useNavigate } from 'react-router-dom';
import { clearFilms } from '@redux/slices/homepage-films-slice';

interface HamburgerMenuProps {
    isUserAuth: boolean;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ isUserAuth }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleDropdown = (): void => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleLogout = async (): Promise<void> => {
        // TODO: при выходе нужно перенаправлять на login,
        //  разобраться что идет не так (сначала рендерится homepage, потом идет редирект)
        setIsDropdownOpen(false);
        localStorage.clear();
        const auth = getAuth();
        dispatch(clearSearchResults());
        dispatch(logout());
        dispatch(clearFilms());
        await signOut(auth);
        navigate('/auth/login');
    };

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent): void => {
            if (
                dropdownRef.current &&
                buttonRef.current &&
                !dropdownRef.current.contains(e.target as Node) &&
                !buttonRef.current.contains(e.target as Node)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return (): void => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button
                ref={buttonRef}
                onClick={toggleDropdown}
                className={style.navbar}
            >
                <GiHamburgerMenu />
            </button>

            {isDropdownOpen && (
                <div className={style.dropdown} ref={dropdownRef}>
                    {isUserAuth ? (
                        <div>
                            <LinkButton
                                to="/userpage"
                                onClick={toggleDropdown}
                                icon={<FaUser />}
                                nameP="Мой профиль"
                            />
                            <LinkButton
                                to="/bookmarks"
                                onClick={toggleDropdown}
                                icon={<IoBookmarks />}
                                nameP="Избранное"
                            />
                            <LinkButton
                                to="/auth/login"
                                onClick={handleLogout}
                                icon={<IoEnter />}
                                nameP="Выход"
                            />
                        </div>
                    ) : (
                        <div>
                            <LinkButton
                                to="auth/login"
                                onClick={toggleDropdown}
                                icon={<IoEnter />}
                                nameP="Войти"
                            />
                            <LinkButton
                                to="auth/register"
                                onClick={toggleDropdown}
                                icon={<IoEnter />}
                                nameP="Зарегистрироваться"
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default HamburgerMenu;
