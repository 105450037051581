import style from './user-ratings.module.scss';
import { Film } from '@lib/types';
import { IoStar } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import { get, ref, remove, set } from 'firebase/database';
import { db } from '@firebaselocal';
import { toast } from 'react-hot-toast';
import PosterContainer from '@components/poster-container/poster-container';

type Props = {
    film: Film;
    userRating?: number;
    userId: string;
    onDelete: () => void;
};

const UserRatings = ({ film, userRating, userId, onDelete }: Props) => {
    const URL = 'https://image.tmdb.org/t/p/w92';
    const navigate = useNavigate();

    const removeUserRating = async () => {
        const userRatingRef = ref(db, `users/${userId}/ratings/${film.id}`);
        await remove(userRatingRef);
    };

    const removeFilmRating = async () => {
        const filmRatingRef = ref(db, `films/${film.id}/ratings/${userId}`);
        await remove(filmRatingRef);
    };

    const changeAverageRating = async () => {
        const ratingsRef = ref(db, `films/${film.id}/ratings`);
        const snapshot = await get(ratingsRef);

        if (!snapshot.exists()) {
            const averageRatingRef = ref(db, `films/${film.id}/average_rating`);
            await remove(averageRatingRef);
        } else {
            const ratings = snapshot.val();

            if (
                ratings &&
                typeof ratings === 'object' &&
                !Array.isArray(ratings)
            ) {
                const totalRatings = Object.values(ratings).filter(
                    (value) => typeof value === 'number'
                );

                if (totalRatings.length > 0) {
                    const averageRating =
                        totalRatings.reduce((sum, rating) => sum + rating, 0) /
                        totalRatings.length;

                    const averageRatingRef = ref(
                        db,
                        `films/${film.id}/average_rating`
                    );
                    await set(averageRatingRef, averageRating);
                } else {
                    console.error('В рейтинге нет числовых значений.');
                }
            } else {
                console.error('Неверный формат данных для рейтинга.');
            }
        }
    };

    const deleteRating = async () => {
        try {
            await removeUserRating();
            await removeFilmRating();
            await changeAverageRating();

            toast.success('Оценка успешно удалена!');
            onDelete();
        } catch (error) {
            toast.error(`Ошибка при удалении оценки: ${error}`);
        }
    };

    const handleDeleteRating = async (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        deleteRating();
    };

    return (
        <div
            className={style.container}
            onClick={() => navigate(`/film/${film.id}`)}
        >
            <div className={style.image}>
                <PosterContainer
                    src={`${URL}${film.poster_path}`}
                    alt={`${film.title}`}
                />
            </div>

            <div className={style.userInfo}>
                <h4 className={style.title}>{film.title}</h4>
                <div className={style.rate}>
                    <IoStar className={style.star} /> {userRating}
                </div>
            </div>

            <button className={style.deleteButton} onClick={handleDeleteRating}>
                Удалить оценку
                <MdDeleteForever />
            </button>
        </div>
    );
};

export default UserRatings;
