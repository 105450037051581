import React, { useState } from 'react';
import style from './poster-container.module.scss';
import Loader from '@components/loader/loader';
import { loadingFilmByIdSelector } from '@redux/selectors/selectors';
import { useSelector } from 'react-redux';

type PosterContainerProps = {
    src: string;
    alt: string;
};

const PosterContainer: React.FC<PosterContainerProps> = ({ src, alt }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const isFilmLoading = useSelector(loadingFilmByIdSelector);

    return (
        <div className={style.posterContainer}>
            {isFilmLoading && <Loader radius={50} />}

            {!isFilmLoading && (
                <img
                    src={src}
                    alt={alt}
                    data-loaded={isLoaded}
                    onLoad={() => setIsLoaded(true)}
                    className={style.poster}
                />
            )}
        </div>
    );
};

export default PosterContainer;
