import style from './home-page.module.scss';
import React, { useEffect } from 'react';
import ListOfFilms from '@components/homepage-films/list-of-films';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomepageFilms } from '@redux/thunks/fetch-films-TMDB-thunk';
import { AppDispatch } from '@redux/store/store';
import Loader from '@components/loader/loader';
import { homepageFilmsSelector } from '@redux/selectors/selectors';

const HomePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { recommended, trending, newReleases, error, loading } = useSelector(
        homepageFilmsSelector
    );

    useEffect(() => {
        dispatch(fetchHomepageFilms());
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <div className={style.error}> {error}</div>;
    }

    if (!loading) {
        return (
            <div className={style.container}>
                <ListOfFilms heading="Новые релизы" films={newReleases} />
                <ListOfFilms heading="Высокий рейтинг" films={trending} />
                <ListOfFilms heading="Скоро в кино" films={recommended} />

                {/*TODO: добавить подборки по жанрам*/}
                {/*TODO: добавить подборки для авторизованных пользователей    */}
            </div>
        );
    }
};

export default HomePage;
