import { createAsyncThunk } from '@reduxjs/toolkit';
import { ref, get } from 'firebase/database';
import { db } from '../../firebase';
import { User } from '@lib/types';

export const fetchUserDataFromFB = createAsyncThunk<
    User | null,
    string,
    { rejectValue: string }
>('user/fetchUserDataFromFB', async (userId, { rejectWithValue }) => {
    const userRef = ref(db, `users/${userId}`);
    try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
            const data = snapshot.val();
            return {
                userId: data.userId,
                username: data.username,
                email: data.email,
                token: data.token,
                permission: data.permission,
                lists: data.lists || null,
                ratings: data.ratings || null,
            };
        } else {
            throw new Error('Пользователь не найден');
        }
    } catch (error) {
        return rejectWithValue(`Ошибка при загрузке данных: ${error}`);
    }
});
