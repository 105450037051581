export const API_BASE_URL = 'https://api.themoviedb.org/3';
export const ACCESS_TOKEN = import.meta.env.VITE_TMDB_ACCESS_TOKEN;

export const getApiConfig = () => ({
    headers: { Authorization: `Bearer ${ACCESS_TOKEN}` },
    params: {
        language: 'ru-RU',
        format: 'webp',
    },
});
