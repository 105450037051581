import { configureStore } from '@reduxjs/toolkit';
import userSlice from '../slices/user-slice';
import searchSlice from '../slices/search-films-slice';
import homepageFilmsSlice from '../slices/homepage-films-slice';
import filmByIdSlice from '../slices/film-by-id-slice';
import filmFromFBSlice from '@redux/slices/film-from-FB-slice';
import bookmarksFilmsSlice from '@redux/slices/bookmarks-films-slice';
import filmsByIdsSlice from '@redux/slices/films-by-ids-slice';

const store = configureStore({
    reducer: {
        user: userSlice,
        homepageFilms: homepageFilmsSlice,
        search: searchSlice,
        filmById: filmByIdSlice,
        filmFromFB: filmFromFBSlice,
        lists: bookmarksFilmsSlice,
        filmsByIds: filmsByIdsSlice,
    },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
