import React from 'react';
import { Link } from 'react-router-dom';
import style from './film.module.scss';
import PosterContainer from '@components/poster-container/poster-container';
import type { Film as FilmData } from '@lib/types';

type FilmProps = {
    film: FilmData;
};
// TODO: если это страница закладок или права администратора, то добавить кнопку удаления.

const Film = ({ film }: FilmProps) => {
    const ratingTMDB = film.vote_average.toFixed(1);
    const ratingFW = film.average_rating?.toFixed(1);
    return (
        <div className={style.container}>
            <Link to={`/film/${film.id}`} className={style.link}>
                <PosterContainer
                    src={`https://image.tmdb.org/t/p/w342${film.poster_path}`}
                    alt={film.title}
                />

                <div className={style.ratings}>
                    <div className={style.rating}>
                        <span className={style.studio}>TMDB</span>: {ratingTMDB}
                    </div>

                    {ratingFW && (
                        <div className={style.rating}>
                            <span className={style.studio}>FW</span>: {ratingFW}
                        </div>
                    )}
                </div>

                <h3 className={style.title}>{film.title}</h3>
            </Link>
        </div>
    );
};

export default Film;
