import style from './list-of-films.module.scss';
import React from 'react';
import Film from './film/film';
import type { Film as FilmData } from '@lib/types';
import ListOfFilmsScroll from './homepage-films-scroll/list-of-films-scroll';
import HeadingButton from './heading-button/heading-button';

type Props = {
    heading?: string;
    films: FilmData[];
};

const ListOfFilms = ({ heading, films }: Props) => {
    return (
        <div className={style.container}>
            <HeadingButton films={films} heading={heading} />

            <div className={style.navigation}>
                <ListOfFilmsScroll>
                    {films.map((film) => (
                        <div key={film.id} className={style.film}>
                            <Film film={film} />
                        </div>
                    ))}
                </ListOfFilmsScroll>
            </div>
        </div>
    );
};

export default ListOfFilms;
