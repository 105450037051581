import style from './filmset-page.module.scss';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Film as FilmType } from '@lib/types';
import Film from '@components/homepage-films/film/film';
import ButtonBack from '@components/button-back/button-back';

const FilmsetPage = () => {
    const location = useLocation();
    const { films = [], title = '' } = location.state || {};

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
    }, []);

    // TODO: обработать ошибки
    // TODO: добавить возможность удаления подборки если она создана пользователем
    return (
        <div className={style.container}>
            <div className={style.nav}>
                <ButtonBack />
                <h1 className={style.title}> {title} </h1>
            </div>
            <div className={style.films}>
                {films.map((film: FilmType) => (
                    <div className={style.film} key={film.id}>
                        <Film film={film} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default React.memo(FilmsetPage);
