import { RootState } from '../store/store';
import { Film, HomepageFilms } from '@lib/types';
import type { User, FirebaseFilmData } from '@lib/types';

// user
export const isUserAuthSelector = (state: RootState): boolean =>
    !!state.user.token;

export const userDataSelector = (state: RootState): User => state.user;

export const userListsFromFBSelector = (state: RootState) => state.user.lists;

// search-films
export const searchResultsSelector = (state: RootState): Film[] =>
    state.search.searchResults;

export const searchLoadingSelector = (state: RootState): boolean =>
    state.search.loading;

// film-by-id
export const loadingFilmByIdSelector = (state: RootState): boolean =>
    state.filmById.loading;

export const errorFilmByIdSelector = (state: RootState): null | string =>
    state.filmById.error;

export const FilmByIdDetailsSelector = (state: RootState): Film | null =>
    state.filmById.filmDetails;

// films-by-ids (lists)
export const loadingListsOfFilmsByIdsSelector = (state: RootState): boolean =>
    state.lists.loading;

export const errorListsOfFilmsByIdsSelector = (
    state: RootState
): null | string => state.lists.error;

export const dataListsOfFilmsByIdsSelector = (state: RootState) =>
    state.lists.films;

// homepage-films
export const homepageFilmsSelector = (state: RootState): HomepageFilms =>
    state.homepageFilms;

// firebase-films
export const filmDataFBSelector = (
    state: RootState
): FirebaseFilmData | undefined => state.filmFromFB.data;

export const loadingFilmDataFBSelector = (state: RootState): boolean =>
    state.filmFromFB.loading;

export const errorFilmDataFBSelector = (state: RootState): string | undefined =>
    state.filmFromFB.error;

export const currentFilmIdSelector = (state: RootState): number | undefined =>
    state.filmFromFB.currentFilmId;

export const currentFilmAverageRatingSelector = (
    state: RootState
): number | undefined => state.filmFromFB.currentFilmAverageRating;

// filmsByIds
export const userFilmsSelector = (state: RootState) => state.filmsByIds.films;

export const userFilmsLoadingSelector = (state: RootState) =>
    state.filmsByIds.loading;
