import { createAsyncThunk } from '@reduxjs/toolkit';
import { ref, get } from 'firebase/database';
import { db } from '../../firebase';
import { FirebaseFilmData } from '@lib/types';

export const fetchFilmDataFromFB = createAsyncThunk<
    FirebaseFilmData,
    number,
    { rejectValue: string }
>('film/fetchFilmDataFromFB', async (filmId, { rejectWithValue }) => {
    const filmRef = ref(db, `films/${filmId}`);
    try {
        const snapshot = await get(filmRef);
        if (snapshot.exists()) {
            return snapshot.val() as FirebaseFilmData;
        } else {
            throw new Error('Фильм не найден');
        }
    } catch (error) {
        return rejectWithValue(`Ошибка при загрузке данных: ${error}`);
    }
});
