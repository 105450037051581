import React, { useState } from 'react';
import style from './comments.module.scss';
import dateFormat from '../../../../assets/utils/date-format';
import type { Comment } from '@lib/types';
import { useSelector } from 'react-redux';
import { filmDataFBSelector } from '@redux/selectors/selectors';
import { userDataSelector } from '@redux/selectors/selectors';
import { v4 as uuidv4 } from 'uuid';
import { db } from '@firebaselocal';
import { ref, set, remove } from 'firebase/database';
import { currentFilmIdSelector } from '@redux/selectors/selectors';
import { toast } from 'react-hot-toast';
import { MdDeleteForever } from 'react-icons/md';

const Comments = () => {
    const [newCommentText, setNewCommentText] = useState<string>('');
    const userData = useSelector(userDataSelector);
    const filmDataFB = useSelector(filmDataFBSelector);
    const filmId = useSelector(currentFilmIdSelector);
    const initialComments = Object.values(filmDataFB?.comments || []).sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    const [comments, setComments] = useState<Comment[]>(initialComments || []);

    const sendComment = async (newComment: Comment) => {
        if (!userData.username || !filmId) {
            return;
        } else {
            try {
                const commentRef = ref(
                    db,
                    `films/${filmId}/comments/${newComment.commentId}`
                );
                await set(commentRef, newComment);

                toast.success('Комментарий успешно добавлен!');
            } catch (error) {
                toast.error(`Ошибка добавления комментария: ${error}`);
            }
        }
    };

    const deleteComment = async (commentId: string) => {
        if (!filmId || !commentId) {
            return;
        }

        try {
            const commentRef = ref(db, `films/${filmId}/comments/${commentId}`);
            await remove(commentRef);
            setComments((prevComments) =>
                prevComments.filter(
                    (comment) => comment.commentId !== commentId
                )
            );
            toast.success('Комментарий успешно удалён!');
        } catch (error) {
            toast.error(`Ошибка при удалении комментария: ${error}`);
        }
    };

    const addComment = (newComment: Comment) => {
        setComments((prevComments) => [newComment, ...prevComments]);
        sendComment(newComment);
    };

    const handleAddComment = () => {
        if (!userData.username) {
            toast.error('Чтобы добавить комментарий необходимо авторзоваться');
            return;
        }
        if (newCommentText.trim() && userData?.userId && userData?.username) {
            const newComment: Comment = {
                commentId: uuidv4(),
                userId: userData?.userId,
                username: userData?.username,
                text: newCommentText,
                date: new Date().toISOString(),
            };
            addComment(newComment);
            setNewCommentText('');
        }
    };

    return (
        <div className={style.container}>
            {/*TODO: добавить пагинацию*/}
            <div className={style.allComments}>
                {comments.length > 0 ? (
                    comments.map((comment: Comment) => (
                        <div
                            key={comment.commentId}
                            className={style.commentContainer}
                        >
                            <div className={style.userInfoContainer}>
                                <div className={style.userInfo}>
                                    <h3>{comment.username}</h3>

                                    <span className={style.date}>
                                        {new Date(comment.date)
                                            .toLocaleString('ru-RU', dateFormat)
                                            .replace(/ г\./, '')}
                                    </span>
                                </div>
                                <div className={style.commentText}>
                                    <p>{comment.text}</p>
                                </div>
                            </div>
                            {(userData.userId === comment.userId ||
                                userData.permission === 'admin') && (
                                <button
                                    className={style.deleteButton}
                                    title="Удалить комментарий"
                                    onClick={() =>
                                        deleteComment(comment.commentId)
                                    }
                                >
                                    <MdDeleteForever />
                                </button>
                            )}
                        </div>
                    ))
                ) : (
                    <h3 className={style.noComments}>Нет комментариев</h3>
                )}
            </div>
            <div className={style.addComment}>
                <input
                    value={newCommentText}
                    onChange={(e) => setNewCommentText(e.target.value)}
                    placeholder="Напишите комментарий..."
                    className={style.input}
                />
                <button className={style.addButton} onClick={handleAddComment}>
                    Добавить
                </button>
            </div>
        </div>
    );
};

export default Comments;
