import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ref, onValue } from 'firebase/database';
import { db } from '../../firebase';
import { setUserRatings } from '@redux/slices/user-slice';

const useUserRatingsSubscription = (userId: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const ratingsRef = ref(db, `users/${userId}/ratings`);
        const unsubscribe = onValue(ratingsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                dispatch(setUserRatings(data));
            }
        });

        return () => {
            unsubscribe();
        };
    }, [userId, dispatch]);
};

export default useUserRatingsSubscription;
