import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@lib/types';
import { fetchUserDataFromFB } from '@redux/thunks/fetch-user-data-FB-thunk';

const initialState: User = {
    token: undefined,
    username: undefined,
    email: undefined,
    userId: undefined,
    permission: undefined,
    lists: undefined,
    ratings: undefined,
    loading: false,
    error: undefined,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setUserEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setUserToken: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setUserLists: (state, action: PayloadAction<any>) => {
            state.lists = action.payload;
        },
        setUserRatings: (state, action: PayloadAction<any>) => {
            state.ratings = action.payload;
        },
        clearUserRating: (state, action: PayloadAction<number>) => {
            if (state.ratings && state.ratings[action.payload]) {
                const newRatings = { ...state.ratings };
                delete newRatings[action.payload];
                state.ratings = newRatings;
            }
        },

        login: (state, action: PayloadAction<User>) => {
            state.token = action.payload.token;
            state.username = action.payload.username;
            state.email = action.payload.email;
            state.userId = action.payload.userId;
        },
        logout: (state) => {
            state.token = undefined;
            state.username = undefined;
            state.email = undefined;
            state.userId = undefined;
            state.permission = undefined;
            state.lists = undefined;
            state.loading = false;
            state.error = undefined;
            state.ratings = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDataFromFB.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(fetchUserDataFromFB.fulfilled, (state, action) => {
                if (action.payload) {
                    state.lists = action.payload.lists;
                    state.ratings = action.payload.ratings;
                }
                state.loading = false;
            })
            .addCase(fetchUserDataFromFB.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const {
    setUsername,
    setUserId,
    setUserEmail,
    login,
    logout,
    setUserToken,
    setUserLists,
    setUserRatings,
    clearUserRating,
} = userSlice.actions;
export default userSlice.reducer;
