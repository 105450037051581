import style from './search-result.module.scss';
import type { Film } from '@lib/types';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

type Props = {
    film: Film;
    clearResults: () => void;
};

const SearchResult: React.FC<Props> = ({ film, clearResults }) => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const posterUrl = `https://image.tmdb.org/t/p/w92${film.poster_path}`;

    const genres = film.genres?.map((genre) => genre.name).join(', ');

    const goToFilm = (): void => {
        clearResults();
        navigate(`/film/${film.id}`);
    };

    return (
        <button className={style.container} onClick={goToFilm}>
            <div className={style.left}>
                <h6 className={style.h6}>
                    {film.title}
                    <span className={style.span}>
                        ({film.vote_average.toFixed(1)})
                    </span>
                </h6>
            </div>

            {genres && <p>Жанр: {genres}</p>}

            <div className={style.posterContainer}>
                <img
                    src={posterUrl}
                    className={style.poster}
                    alt={film.title}
                    data-loaded={isLoaded}
                    onLoad={() => setIsLoaded(true)}
                />
            </div>
        </button>
    );
};

export default SearchResult;
