import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/app';
import './styles/index.scss';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { BrowserRouter } from 'react-router-dom';
import './firebase';
import * as Sentry from '@sentry/react';

const rootElement = document.getElementById('root');
if (!rootElement) {
    throw new Error('Root element is missing');
}

Sentry.init({
    dsn: 'https://847847248d21fd3f28bfc39ea05753f5@o4508324693082112.ingest.de.sentry.io/4508324698849360',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/filmwood\.ru/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
