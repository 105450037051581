import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFilmDataFromFB } from '@redux/thunks/fetch-films-FB-thunk';
import { FirebaseFilmData } from '@lib/types';

type FilmState = {
    data: FirebaseFilmData | undefined;
    loading: boolean;
    error: string | undefined;
    currentFilmId: number | undefined;
    currentFilmAverageRating: number | undefined;
};

const initialState: FilmState = {
    data: undefined,
    loading: false,
    error: undefined,
    currentFilmId: undefined,
    currentFilmAverageRating: undefined,
};

const filmFromFBSlice = createSlice({
    name: 'film',
    initialState,
    reducers: {
        clearFilmDataFromFB: (state) => {
            state.data = undefined;
            state.loading = false;
            state.error = undefined;
            state.currentFilmId = undefined;
            state.currentFilmAverageRating = undefined;
        },
        setCurrentFilmId: (state, action: PayloadAction<number>) => {
            state.currentFilmId = action.payload;
        },
        setCurrentFilmAverageRating: (state, action: PayloadAction<number>) => {
            state.currentFilmAverageRating = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilmDataFromFB.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(fetchFilmDataFromFB.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchFilmDataFromFB.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const {
    clearFilmDataFromFB,
    setCurrentFilmId,
    setCurrentFilmAverageRating,
} = filmFromFBSlice.actions;
export default filmFromFBSlice.reducer;
