import style from './app.module.scss';
import AppRoutes from '../../app-routes/app-routes';
import Header from '../header/header';
import React from 'react';
import Wrapper from '@components/wrapper/wrapper';
import { Toaster } from 'react-hot-toast';
import useAuthFromStorage from '@lib/hooks/use-auth-from-storage';

const App: React.FC = () => {
    useAuthFromStorage();

    return (
        <div className={style.container}>
            <Toaster position="top-left" />
            <Header />
            <Wrapper>
                <AppRoutes />
            </Wrapper>
        </div>
    );
};

export default App;
