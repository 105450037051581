import { createSlice } from '@reduxjs/toolkit';
import { FilmById } from '@lib/types';
import { fetchFilmById } from '@redux/thunks/fetch-films-TMDB-thunk';

const initialState: FilmById = {
    filmDetails: null,
    loading: false,
    error: null,
};

const filmByIdSlice = createSlice({
    name: 'films',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchFilmById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilmById.fulfilled, (state, action) => {
                state.loading = false;
                state.filmDetails = action.payload;
            })
            .addCase(fetchFilmById.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message || 'Failed to load film details';
            });
    },
});

export default filmByIdSlice.reducer;
