import style from '../auth.module.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '@lib/hooks/use-auth';
import { toast } from 'react-hot-toast';
import Loader from '@components/loader/loader';
import authErrorMessages from '../../../assets/utils/auth-error-messages';

type Form = {
    username: string;
    email: string;
    password: string;
    confirmationPassword: string;
};

const RegistrationPage: React.FC = () => {
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { registerUser } = useAuth();

    const [form, setForm] = useState<Form>({
        username: '',
        email: '',
        password: '',
        confirmationPassword: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (
        e: React.FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();
        setErrorMessage('');
        setHasError(false);

        if (!form.username || !form.password || !form.email) {
            return;
        }

        if (form.password !== form.confirmationPassword) {
            setErrorMessage('Пароли не совпадают');
            setHasError(true);
            return;
        }

        setIsLoading(true);
        try {
            const authError = await registerUser(
                form.email,
                form.password,
                form.username
            );

            if (authError) {
                setErrorMessage(
                    authErrorMessages[authError.code] ||
                        'Произошла ошибка регистрации.'
                );
                setHasError(true);
            } else {
                toast.success('Регистрация прошла успешно!', {
                    duration: 3000,
                });
            }
        } catch (error: unknown) {
            console.error('Ошибка при регистрации:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={style.container}>
            <h3 className={style.h3}>Регистрация</h3>
            <form className={style.form} onSubmit={handleSubmit}>
                <input
                    className={style.formInput}
                    type="text"
                    name="username"
                    placeholder="Имя пользователя"
                    value={form.username}
                    onChange={handleChange}
                    maxLength={40}
                    required
                />

                <input
                    className={style.formInput}
                    type="email"
                    name="email"
                    placeholder="Почта"
                    value={form.email}
                    onChange={handleChange}
                    required
                />

                <input
                    className={style.formInput}
                    type="password"
                    name="password"
                    placeholder="Пароль"
                    value={form.password}
                    onChange={handleChange}
                    maxLength={30}
                    required
                />

                <input
                    className={style.formInput}
                    type="password"
                    name="confirmationPassword"
                    placeholder="Подтвердите пароль"
                    value={form.confirmationPassword}
                    onChange={handleChange}
                    maxLength={30}
                    required
                />

                <button
                    className={style.formButton}
                    type="submit"
                    data-is-disabled={isLoading}
                    disabled={isLoading}
                >
                    <div className={style.loader}>
                        {isLoading && <Loader radius={20} />}
                        {!isLoading && 'Зарегистрироваться'}
                    </div>
                </button>

                <div className={style.regSign}>
                    <p className={style.haveAcc}>Уже есть аккаунт?</p>
                    <Link to="/auth/login">
                        <div className={style.regSignButton}>Войти</div>
                    </Link>
                </div>
            </form>
            <div className={style.error}>{hasError && errorMessage}</div>
        </div>
    );
};

export default RegistrationPage;
