import style from './user-page.module.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaQuestion } from 'react-icons/fa';
import UserRatings from '@pages/user-page/user-ratings/user-ratings';
import { fetchUserDataFromFB } from '@redux/thunks/fetch-user-data-FB-thunk';
import useAuthStatus from '@lib/hooks/use-auth-status';
import Loader from '@components/loader/loader';
import { AppDispatch } from '@redux/store/store';
import {
    userDataSelector,
    userFilmsSelector,
    userFilmsLoadingSelector,
} from '@redux/selectors/selectors';
import { fetchFilmsByIds } from '@redux/thunks/fetch-films-TMDB-thunk';
import useUserRatingsSubscription from '@lib/hooks/use-user-ratings-subscription';
import { clearFilmById } from '@redux/slices/films-by-ids-slice';
import { clearUserRating } from '@redux/slices/user-slice';

const UserPage: React.FC = () => {
    const { userData, loadingUserData } = useAuthStatus();
    const dispatch = useDispatch<AppDispatch>();

    const userDataFB = useSelector(userDataSelector);
    const userFilms = useSelector(userFilmsSelector);

    const userRatings = userDataFB?.ratings;
    const loadingUserFilms = useSelector(userFilmsLoadingSelector);

    const filmIds = userRatings ? Object.keys(userRatings).map(Number) : [];

    const [films, setFilms] = useState(userFilms);
    const [rating, setRating] = useState(userRatings);

    const handleDeleteFilm = (id: number) => {
        dispatch(clearFilmById(id));
        dispatch(clearUserRating(id));
    };

    useEffect(() => {
        if (userData && !loadingUserData) {
            dispatch(fetchUserDataFromFB(userData.uid));
        }
    }, [dispatch, userData]);

    useUserRatingsSubscription(userData?.uid ?? '');

    useEffect(() => {
        if (filmIds.length > 0) {
            dispatch(fetchFilmsByIds({ filmsIds: filmIds }));
        }
    }, [dispatch, userRatings]);

    useEffect(() => {
        setFilms(userFilms);
    }, [userFilms]);

    useEffect(() => {
        setRating(rating);
    }, [userRatings]);

    if (loadingUserData || userDataFB.loading || loadingUserFilms) {
        return <Loader />;
    }

    if (!userData) {
        return 'Данные не получены';
    }

    // TODO: обработать ошибки

    return (
        // TODO: сортировать пользовательский рейтинг по дате (сначала сделать в stars)
        <div className={style.container}>
            <section className={style.topSection}>
                <div className={style.avatar}>
                    <FaQuestion className={style.icon} />
                </div>
                <div className={style.userInfo}>
                    <h1>{userData?.displayName}</h1>
                    <p>email: {userData?.email}</p>
                </div>
            </section>

            <section className={style.userRatings}>
                <h2 className={style.h2}>Мои оценки ({films.length})</h2>

                {films.length === 0 && (
                    <p>Вы еще не оценили ни одного фильма</p>
                )}

                <div className={style.allRatings}>
                    {films &&
                        userRatings &&
                        films.map((film) => (
                            <div className={style.rating} key={film.id}>
                                <UserRatings
                                    film={film}
                                    userRating={userRatings[film.id]}
                                    userId={userData.uid}
                                    onDelete={() => handleDeleteFilm(film.id)}
                                />
                            </div>
                        ))}
                </div>
            </section>
        </div>
    );
};

export default UserPage;
