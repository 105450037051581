import { useDispatch } from 'react-redux';
import { AuthData } from '@lib/types';
import { useNavigate } from 'react-router-dom';
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
    User as FirebaseUser,
} from 'firebase/auth';
import { login } from '@redux/slices/user-slice';
import { ref, set } from 'firebase/database';
import { FirebaseError } from 'firebase/app';

import { db } from '../../firebase';
const useAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateUserState = (user: FirebaseUser, token: string) => {
        const authData: AuthData = {
            username: user.displayName || '',
            email: user.email || '',
            token,
            userId: user.uid,
        };
        dispatch(login(authData));

        localStorage.setItem('authToken', token);
        localStorage.setItem('authUsername', user.displayName || '');
        localStorage.setItem('authEmail', user.email || '');
        localStorage.setItem('authUserId', user.uid || '');
    };

    const registerUser = async (
        email: string,
        password: string,
        username: string
    ) => {
        const auth = getAuth();

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;

            await updateProfile(user, { displayName: username });
            const token = await user.getIdToken();

            await set(ref(db, 'users/' + user.uid), {
                username: user.displayName,
                email: user.email,
                permission: 'user',
                createdDate: new Date().toISOString(),
            });

            updateUserState(user, token);
            navigate('/');
            return null;
        } catch (error: unknown) {
            if (error instanceof FirebaseError) {
                console.error('Ошибка регистрации', error);
                return error;
            }
        }
    };

    const loginUser = async (email: string, password: string) => {
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;
            const token = await user.getIdToken();

            if (user.displayName && user.email && token) {
                updateUserState(user, token);
            }

            navigate('/');
            return null;
        } catch (error: unknown) {
            if (error instanceof FirebaseError) {
                console.error('Ошибка входа', error);
                return error;
            }
        }
    };

    return { registerUser, loginUser };
};

export default useAuth;
