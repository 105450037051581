const authErrorMessages: Record<string, string> = {
    'auth/email-already-in-use': 'Этот email уже используется.',
    'auth/invalid-email': 'Некорректный email.',
    'auth/weak-password': 'Пароль должен содержать не менее 6 символов.',

    'auth/invalid-credential': 'Неверный email или пароль',
    'auth/user-not-found': 'Пользователь с таким email не найден.',
    'auth/wrong-password': 'Неверный пароль.',
};

export default authErrorMessages;
