import React, { useRef, useState, useEffect } from 'react';
import style from './list-of-films-scroll.module.scss';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

type Props = {
    children: React.ReactNode;
};

const ListOfFilmsScroll: React.FC<Props> = ({ children }) => {
    const filmSectionRef = useRef<HTMLDivElement>(null);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(true);

    const scroll = (direction: string) => {
        if (direction === 'left' && filmSectionRef.current) {
            filmSectionRef.current.scrollBy({
                left: -window.innerWidth / 2,
                behavior: 'smooth',
            });
        }
        if (direction === 'right' && filmSectionRef.current) {
            filmSectionRef.current.scrollBy({
                left: window.innerWidth / 2,
                behavior: 'smooth',
            });
        }
    };

    const handlePrevClick = (): void => {
        scroll('left');
    };

    const handleNextClick = (): void => {
        scroll('right');
    };

    const updateScrollButtons = (): void => {
        if (filmSectionRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } =
                filmSectionRef.current;
            setCanScrollPrev(scrollLeft > 0);
            setCanScrollNext(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();
        const ref = filmSectionRef.current;
        if (ref) {
            ref.addEventListener('scroll', updateScrollButtons);
            window.addEventListener('resize', updateScrollButtons);
            return (): void => {
                ref.removeEventListener('scroll', updateScrollButtons);
                window.removeEventListener('resize', updateScrollButtons);
            };
        }
    }, [children]);

    return (
        <div className={style.container}>
            <div className={style.navigation}>
                <button
                    className={`${style.moveButton} ${style.prevButton}`}
                    onClick={handlePrevClick}
                    data-is-scrollable={canScrollPrev}
                >
                    <MdNavigateBefore />
                </button>

                <button
                    className={`${style.moveButton} ${style.nextButton}`}
                    onClick={handleNextClick}
                    data-is-scrollable={canScrollNext}
                >
                    <MdNavigateNext />
                </button>
            </div>

            <div ref={filmSectionRef} className={style.filmSection}>
                {children}
            </div>
        </div>
    );
};

export default ListOfFilmsScroll;
