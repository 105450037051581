import style from './button-back.module.scss';
import { IoCaretBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ButtonBack = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <button onClick={handleBackClick} className={style.container}>
            <IoCaretBackOutline />
        </button>
    );
};

export default ButtonBack;
