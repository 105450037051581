import { createSlice } from '@reduxjs/toolkit';
import { SearchFilm } from '@lib/types';
import { fetchFilmByTitle } from '../thunks/fetch-films-TMDB-thunk';

const initialState: SearchFilm = {
    searchResults: [],
    loading: false,
    error: null,
};

const searchFilmsSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        clearSearchResults(state) {
            state.searchResults = [];
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilmByTitle.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.searchResults = [];
            })
            .addCase(fetchFilmByTitle.fulfilled, (state, action) => {
                state.loading = false;
                state.searchResults = action.payload;
            })
            .addCase(fetchFilmByTitle.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message || 'Failed to load search results';
            });
    },
});

export const { clearSearchResults } = searchFilmsSlice.actions;
export default searchFilmsSlice.reducer;
