import { Film } from '@lib/types';
import style from './film-page-top-section.module.scss';
import PosterContainer from '@components/poster-container/poster-container';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentFilmAverageRatingSelector } from '@redux/selectors/selectors';
import { ref, onValue } from 'firebase/database';
import { db } from '@firebaselocal';
import { useDispatch } from 'react-redux';
import { setCurrentFilmAverageRating } from '@redux/slices/film-from-FB-slice';
import ButtonAddToFavorite from '@components/button-add-to-favorite/button-add-to-favorite';

type Props = {
    filmDetails: Film;
    genres?: string;
    releaseDate?: string;
};

const FilmPageTopSection = ({ filmDetails, genres, releaseDate }: Props) => {
    const URL = 'https://image.tmdb.org/t/p/w342';
    const dispatch = useDispatch();
    const filmId = filmDetails.id;
    const currentFilmRatingFB = useSelector(currentFilmAverageRatingSelector);

    useEffect(() => {
        const averageRatingRef = ref(db, `films/${filmId}/average_rating`);

        const unsubscribe = onValue(averageRatingRef, (snapshot) => {
            const averageRating = snapshot.val();
            if (averageRating !== null) {
                dispatch(setCurrentFilmAverageRating(averageRating));
            }
        });

        return () => unsubscribe();
    }, [filmId, dispatch]);

    return (
        <section className={style.main}>
            <div className={style.left}>
                <div className={style.imgAndButton}>
                    <div className={style.img}>
                        <PosterContainer
                            src={`${URL}${filmDetails.poster_path}`}
                            alt={filmDetails.title}
                        />
                        <div className={style.buttonFavorite}>
                            <ButtonAddToFavorite filmId={filmId} />
                        </div>
                    </div>
                </div>

                <div className={style.ratings}>
                    <div className={style.rating}>
                        <span className={style.studio}>TMDB</span>:{' '}
                        {filmDetails.vote_average.toFixed(1)}
                    </div>

                    {currentFilmRatingFB && (
                        <div className={style.rating}>
                            <span className={style.studio}>FW</span>:{' '}
                            {currentFilmRatingFB.toFixed(1)}
                        </div>
                    )}
                </div>
            </div>

            <div className={style.right}>
                <h2 className={style.title}>{filmDetails.title}</h2>
                <p>{filmDetails.overview}</p>
                <div className={style.overviewSecondary}>
                    {genres && (
                        <div className={style.date}>
                            <h3>Жанр</h3>
                            <p>{genres}</p>
                        </div>
                    )}
                    <div className={style.date}>
                        <h3>Дата выхода</h3>
                        <p>{releaseDate}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FilmPageTopSection;
